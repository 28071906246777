.header {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
}
.header h1 {
  margin: 0;
  color: rgb(84, 137, 228);
}
.header input {
  width: 40%;
  background-color: white;
}
